import axios from 'axios'
import 'axios'

const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) =>
    first === undefined ? '' : first.toLocaleUpperCase(locale) + rest.join('')

export { capitalizeFirstLetter }



const genPasswd = async payload => {
    const JWTtoken = localStorage.getItem(process.env.VUE_APP_NEURAXIS_TOKENNAME);
    axios.defaults.headers.common['Authorization'] = `${JWTtoken}`;

    const request = axios.post("/datahub/genpasswd", { clean: payload });

    return request
        .then(result => {
            return result;
        })
        .catch(error => { return Promise.reject(error); });
}
export { genPasswd };



const getRandomId = (length = 16) => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};
export { getRandomId };

const slugify = (str) => str.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim().replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-');
export { slugify };

const swiss_date = (date) => new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit' })
export { swiss_date };

const swiss_date_time = (date) => new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
export { swiss_date_time };

const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
export { getCurrentDate };

const characters_left = (field, limit) => {
    let chars = field.length
    return limit - chars
}
export { characters_left };

const tags2array = str => {
    const words = str.split(/[, |\s]+/);
    const filteredWords = words.filter(word => word.trim() !== '');
    return filteredWords;
};
export { tags2array };

const captions2array = str => {
    const words = str.split(/;\s*/);
    const filteredWords = words.filter(word => word.trim() !== '');
    return filteredWords;
};
export { captions2array };

const array2captions = arr => {
    return arr.join('; ');
};
export { array2captions };

const arrayToString = arr => arr.join(' ');
export { arrayToString };

const createSlugfromName = (name) => {
    return name.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
}
export {createSlugfromName}

const checkSlugExists = (array, slug) => {
    return array.some(item => item.slug === slug);
}
export {checkSlugExists}

async function asyncTest() {
    return new Promise((resolve) => {
        setTimeout(() => {
            console.log('Executed after 2 seconds');
            resolve();
        }, 2000);
    });
}
export { asyncTest }

const preview_dataset = (key, url, mod = '') => {
    // let day = new Date().getDate()
    // let cs = (1337 * 9999) + day;
    const JWTtoken = localStorage.getItem(process.env.VUE_APP_NEURAXIS_TOKENNAME);
    let now = new Date();
    let cs = Math.floor(now.getTime() / 1000)

    const service_url = `${url}/?key=${key}&preview=${cs}&module=${mod}&token=${JWTtoken}`;
    window.open(service_url)
}
export {preview_dataset}

const isValidURL = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}
export {isValidURL}


async function checkDuplicate(needle, workflow_id, endpoint) {
    try {
        const payload = {
            needle: needle,
            workflow_id: workflow_id
        };

        const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${endpoint}`, payload);

        return response.data;

    } catch (error) {
        console.error('Error checking duplicate:', error);
        throw error;
    }
}
export { checkDuplicate }

function formatToSwissCurrency(value) {
    const formatted = new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(value);
    return formatted.replace('.', ',');
}
export { formatToSwissCurrency }