<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import { uploadSingleImage } from '@/core/var/crud'
import 'vue-advanced-cropper/dist/style.css'
import MenuButton from './Components/Menu-Button.vue'
import DataView from './Components/Data-View.vue'
import ModalButtons from './Components/Modal-Menue.vue'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ImageEditor from './Components/Image-Editor.vue'

const channel = ref(useCustomerStore().getCustomer.customer);

// local variables
const editDialog = ref(false)
const BLOCK_NAME = "standard_image";

// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    image_library: { type: Object, required: true },
    workflow_id: { type: String, required: true }
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const text_image_caption = ref(props.data.text_image_caption)
const template = ref(BLOCK_NAME)
const image = ref(props.data.image)
const image_upload_url = ref()
const croppy = ref(null)
const random_number = ref(String(Math.floor(Math.random() * 100000000)))

const edit = ref(false)

// functions
const edit_block = () => {
    editDialog.value = true
    edit.value = false
    image.value = props.data.image
}

const save_block = () => {
    const img = image_upload_url.value ? image_upload_url.value : image.value;

    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_image_caption": text_image_caption.value,
                "image": img
            },
            "action": "save",
            "index": props.index
        }
    )
    editDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_image_caption": text_image_caption.value,
                "image": image.value
            },
            "action": "add",
            "index": props.index
        }
    )
}

const crop_image = res => {
    croppy.value = res;
    image.value = res.getResult().canvas.toDataURL();
    upload_image()
    edit.value = false
}

const upload_image = async () => {
    await uploadSingleImage(croppy.value, props.index, props.workflow_id, channel.value, 'upload-image', true).then(res => {
        console.log(res);
        image_upload_url.value = res.data.image_url
    });
}
const toggleDialog = () => {
    editDialog.value = false
}
</script>
<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->

    <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" button="pi-image" name="Standard Bild" />

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" name="Standard Bild">
        <Divider />
        <img :src="props.data.image" class="w-full" />
        <div class="text-left p-1 text-sm">{{ props.data.text_image_caption }}</div>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------- Edit Image -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div>
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
        </div>
        <div>
            <ImageEditor :edit="edit" :image_library="image_library" :image="image" @update="crop_image" />

            <div>
                <div class="field col-12 mt-3">
                    <label for="text_image_caption">Bildunterschrift</label>
                    <Textarea v-model="text_image_caption" id="text_image_caption" type="text" :autoResize="true" />
                </div>
            </div>
        </div>
    </Dialog>
</template>