<template>
    <Toast />
    <!-- <LoadingSpinner v-if="loader" size="5" color="fill-primary-600" classs="m-5" /> -->
    <LibraryUpload v-model:visible="imageUploadDialog" :path="endpoints.uploadlibrary" :slug="channel" @data="libraryUploadGetData" @finished="libraryUploadidFinished" />

    <Toolbar>
        <template #start>

            <div class="inline sm:hidden">
                <SplitButton label="Speichern" icon="pi pi-save" :model="items" />
            </div>
            <div class="hidden sm:flex sm:justify-items-start">
                <Button @click="save_changes" v-tooltip.bottom="'Änderungen Speichern'" label="Speichern" class="w-auto mr-4 p-button-success" icon="pi pi-save" :loading="loader" size="small" />
                <Divider layout="vertical" />
                <Button v-tooltip.bottom="'Preview Artikel'" @click="preview" label="Vorschlau" class="mr-2 p-button w-auto" icon="pi pi-eye" size="small" :disabled="disabled" />
                <Button v-tooltip.bottom="'Artikel schliessen'" @click="close_tab" label="Schliessen" class="mr-2 p-button-danger w-auto" icon="pi pi-times-circle" size="small" :disabled="disabled" />
            </div>

        </template>
        <template #end>
            <div class="hidden sm:inline-block">
                <Button type="button" icon="pi pi-refresh" aria-controls="overlay_menu" @click="reset_changes" />
            </div>
        </template>
    </Toolbar>

    <TabView ref="tabview4">
        <TabPanel>
            <template #header>
                <i class="pi pi-file mr-2"></i>
                <span class="mr-3">Meta</span>
            </template>
            <div class="md:flex flex-row md:my-5">
                <div class="w-auto md:w-2/3 m-2">
                    <div class="my-3">
                        <h3>Produktbeschreibung</h3>
                        <Editor editorStyle="width: 100%; min-height: 350px;" v-model="blog.text_content">
                            <template v-slot:toolbar>
                                <span class="ql-formats">
                                    <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                    <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                    <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                    <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                    <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                    <button v-tooltip.bottom="'chatGPT'" @click="chatGptDialog = true">
                                        <CloudIcon class="h-10 w-10" />
                                    </button>
                                </span>
                            </template>
                        </Editor>
                    </div>
                    <!-- <div class="my-3">
                        <h3>Zusammensetzung</h3>
                        <InputText v-model="blog.text_composition" class="w-full" />
                    </div>
                    <div class="my-3">
                        <h3>Fertigung</h3>
                        <Textarea v-model="blog.text_production" class="w-full h-28" />
                    </div> -->
                </div>
                <div class="w-auto md:w-1/3 m-2">
                    <div class="">
                        <Accordion class="accordion-custom" :activeIndex="0">
                            <AccordionTab>
                                <template #header>
                                    <span class="mr-2 font-bold">Metadaten</span>
                                </template>

                                <div class="my-7">
                                    <span class="p-float-label">
                                        <InputText id="title" class="w-full" v-model="blog.title" />
                                        <label for="title">Titel</label>
                                    </span>
                                </div>
                                <div class="my-7">

                                    <span class="p-float-label">
                                        <MultiSelect v-model="new_articles" :options="articles" optionLabel="description" optionValue="sku" placeholder="Artikel wählen" :maxSelectedLabels="3" class="w-full md:w-20rem" selectedItemsLabel="{0} Artikel" filter />
                                        <label for="user">Artikel</label>
                                    </span>
                                </div>

                                    <div class="my-7">
                                        <Button label="Mindestpreis generieren" icon="pi pi-calculator" size="small" class="" @click="generate_pricespan" />
                                    </div>
                                    
                                    <div class="my-7">
                                        <span class="p-float-label">
                                            <InputNumber id="price_range" class="w-full" v-model="blog.min_price" inputId="currency-switzerland" mode="currency" currency="CHF" locale="de-DE" />
                                            <label for="price_range">Mindestpreis</label>
                                        </span>
                                    </div>

                                <div class="my-7">
                                    <span class="p-float-label">
                                        <MultiSelect id="blog_categories" v-model="new_blog_categories" :options="blog_categories" optionLabel="name" optionValue="value" class="w-full" filter />
                                        <label for="blog_categories">Kategorie</label>
                                    </span>
                                </div>

        

                                <div class="my-7">
                                    <span class="p-float-label">
                                        <Dropdown id="blog_status" v-model="blog.status" :options="blog_status" optionLabel="name" optionValue="value" class="w-full" />
                                        <label for="blog_status">Status</label>
                                    </span>
                                </div>
                            </AccordionTab>
                        </Accordion>
                        <Accordion class="accordion-custom mt-3" :activeIndex="image_library.length > 0 ? 0 : false">
                            <AccordionTab>
                                <template #header>
                                    <span class="mr-2 font-bold">Bilder-Verwaltung <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ String(image_library.length) }}</span></span>
                                </template>
                                <div class="flex flex-wrap" v-if="image_library.length > 0">
                                    <UploadImageButton :imagelibrary="image_library" label="Hauptbild" :image="blog.header_image" :workflow_id="workflow_id" @uploaded-image="imageUploaded" :aspect-ratio="{ aspectRatio: 1 / 1 }" image-type="header_image" />

                                    <UploadImageButton v-for="i in 11" :key="i" :imagelibrary="image_library" :label="'Bild ' + i" :image="blog['add_image' + i]" :workflow_id="workflow_id" @uploaded-image="imageUploaded" :aspect-ratio="{ aspectRatio: 1 / 1 }" :image-type="'add_image' + i" />

                                </div>
                                <div v-if="image_library.length == 0">
                                    
                                    <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="mt-4" v-show="image_library.length > 0">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-image mr-2"></i>
                                    <span class="mr-2 font-bold">Bilder Bibliotek</span>
                                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ String(image_library.length) }}</span>
                                </template>

                                <Galleria :value="image_library" v-model:activeIndex="activeImageIndex" :numVisible="7" containerStyle="max-width: 850px" :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false" v-model:visible="displayGallery">
                                    <template #item="slotProps">
                                        <img :src="slotProps.item.image" />
                                    </template>
                                </Galleria>

                                <div v-if="image_library" class="flex flex-wrap">
                                    <div v-for="(image, index) of image_library" :key="index">
                                        <img :src="image.image" class="shadow-2 m-2 w-24 h-24 cursor-pointer object-cover" @click="showGallery(index)" />
                                    </div>
                                </div>
                                <div>
                                    <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" />
                                    <!-- <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder aus der Bibliotek wählen'" label="Bibliotek" class="mr-2 p-button-success w-auto" icon="pi pi-folder" />  -->
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-history mr-2"></i>
                                    <span class="mr-2 font-bold">Änderungsverlauf</span>

                                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ String(blog_changes.length) }}</span>
                                </template>
                                <div v-if="blog_changes">
                                    <div v-for="(change, index) of blog_changes" :key="index" class="my-8 bg-yellow-700/20 p-6 rounded-lg">

                                        <div class="text-xs">
                                            <Chip :label="change.user" icon="pi pi-user" class="m-1" />
                                            <Chip :label="swiss_date_time(change.date)" icon="pi pi-clock" class="m-2" />
                                        </div>
                                        <div class="bg-primary-100 text-white m-2 p-2 rounded-md">
                                            <span class=""> {{ change.message }}</span>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel>
            <template #header>
                <i class="pi pi-box mr-2"></i>
                <span class="mr-3">Builder</span>
            </template>
            <div class="md:flex flex-row my-5">
                <div class="w-auto md:w-2/3 m-2 border-solid border border-gray-200">
                    <DataTable :value="blog_content" :rows="20" responsiveLayout="scroll" :rowHover="true" @rowReorder="onRowReorder">
                        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                        <Column field="template">
                            <template #header>
                                <i class="pi pi-box mr-2"></i>
                                <span class="mr-3">Blocks</span>
                                <span className="inline-flex items-center rounded-full bg-primary-500 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-primary-700/10">
                                    {{ blog_content.length }}
                                </span>
                            </template>
                            <template #body="slotProps">

                                <component v-if="slotProps" :is="content_blocks.find(block => block.name === slotProps.data.template).type" template_type="data" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />

                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="w-auto md:w-1/3 m-5 border-solid border border-gray-200">
                    <div v-for="key in content_blocks" :key="key">
                        <component :is="key.type" template_type="menu" :data="content_blocks" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />
                    </div>
                </div>
            </div>
        </TabPanel>
    </TabView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <Dialog v-model:visible="blog_text_vorschlag_dialog" :style="{ width: '800px' }" header="Teaser Vorschlag" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            {{ blog_text_vorschlag }}
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!--------------- Edit Title Image ------------------>
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImageDialog" :style="{ width: '80%' }" header="Titel Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">

            <div v-if="edit_header_image == false" class="m-3">
                <img :src="blog.header_image" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>

            <span class="inline-block h-48 w-48 overflow-hidden rounded-full bg-gray-100 my-10" v-if="edit_header_image == false && !blog.header_image">
                <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
            </span>
            <Button v-if="edit_header_image == false" label="Bild Bearbeiten" @click="edit_header_image = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />

            <div v-if="edit_header_image == true" class="mt-3">
                <cropper ref="title_image_cropper" :src="blog.header_image" :auto-zoom="true" :auto-center="true" />
            </div>

            <div v-if="edit_header_image == true" class="mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>

            <div v-if="edit_header_image == true" class="mt-3">
                <Button label="Bild Speichern" @click="cropImage(title_image_cropper, 'header_image', 'image123')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
                <!-- <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" /> -->
            </div>
        </div>

    </Dialog>
    <!--------------------------------------------------->
    <!----------------- Preview Image ------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImagePreviewDialog" :style="{ width: '80%' }" header="Titel Vorschau-Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div v-if="edit_header_image_preview == false" class="mt-3">
                <img :src="blog.header_image_preview" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>
            <Button v-if="edit_header_image_preview == false" label="Bild Bearbeiten" @click="edit_header_image_preview = true, blog.header_image_preview = blog.header_image" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            <div v-if="edit_header_image_preview == true" class="mt-3">
                <cropper ref="title_image_cropper_preview" :src="blog.header_image_preview" :auto-zoom="true" :auto-center="true" />
            </div>
            <div v-if="edit_header_image_preview == true" class="mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image_preview = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>
            <div v-if="edit_header_image_preview == true" class="mt-3">
                <Button label="Bild Speichern" @click="crop_header_image(title_image_cropper_preview, 'header_image_preview')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!------------------- GPT Dialog -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="chatGptDialog" :style="{ width: '80%' }" header="Inhalte mit AI erstellen" :modal="true" class="p-fluid">
        <ChatGPT :questions="chatGptQuestions" @chat-callback="update_text_content" />
    </Dialog>
</template>

<script setup>
import { ref, onMounted, watch, defineProps, defineEmits, inject, provide, shallowRef } from 'vue'
import { EnvelopeIcon, ArchiveBoxIcon, CloudIcon } from '@heroicons/vue/20/solid';
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
//import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ChatGPT from '@/core/components/Components/Main-GPT.vue';
import StandardText from '@/core/components/Components/Builder-Blocks/Standard-Text.vue'
import StandardImage from '@/core/components/Components/Builder-Blocks/Standard-Image.vue'
import StandardTitle from '@/core/components/Components/Builder-Blocks/Standard-Title.vue'
import CTAButton from '@/core/components/Components/Builder-Blocks/Cta-Button.vue'
import HtmlBlock from '@/core/components/Components/Builder-Blocks/Html-Block.vue'
import QuoteBlock from '@/core/components/Components/Builder-Blocks/Quote-Block.vue'
import ImageText from '@/core/components/Components/Builder-Blocks/Image-Text.vue'
import ComparsionBlock from '@/core/components/Components/Builder-Blocks/Comparsion-Block.vue'
import QuotingLogo from '@/core/components/Components/Builder-Blocks/Quoting-Logo-Block.vue'
import ImageCols from '@/core/components/Components/Builder-Blocks/Image-Columns.vue'
import Markdown from '@/core/components/Components/Builder-Blocks/Markdown-Block.vue'
import Simplestore from '@/core/var/Simplestore';
import { swiss_date_time, preview_dataset } from '@/core/var/tools'
import { uploadSingleImage } from '@/core/var/crud'
import LibraryUpload from '@/core/components/Components/Main-Library-Upload.vue'
import VideoURL from '@/core/components/Components/Builder-Blocks/Video-URL.vue'
import YouTubeURL from '@/core/components/Components/Builder-Blocks/Youtube-URL.vue'
//import MainButton from '@/core/components/UI/Main-Button.vue'
import UploadImageButton from '@/core/components/Components/Main-UploadImage-Button.vue';

const props = defineProps({
    redis_key: { type: String }
})

const content_blocks = ref([
    { name: 'standard_text', type: shallowRef(StandardText) },
    { name: 'standard_title', type: shallowRef(StandardTitle) },
    { name: 'standard_image', type: shallowRef(StandardImage) },
    { name: 'cta_button', type: shallowRef(CTAButton) },
    { name: 'html_block', type: shallowRef(HtmlBlock) },
    { name: 'quote_block', type: shallowRef(QuoteBlock) },
    { name: 'image_text', type: shallowRef(ImageText) },
    { name: 'comparsion_block', type: shallowRef(ComparsionBlock) },
    { name: 'quoting_logo', type: shallowRef(QuotingLogo) },
    { name: 'image_cols', type: shallowRef(ImageCols) },
    { name: 'video_url', type: shallowRef(VideoURL) },
    { name: 'youtube_url', type: shallowRef(YouTubeURL) },
    { name: 'markdown', type: shallowRef(Markdown) },
]);


const channel = ref(useCustomerStore().getCustomer.customer)
provide('channel', channel)
const SERVICE_URL = inject('SERVICE_URL')


const loader = ref(false)
const toast = useToast()
const confirm2 = useConfirm()
const imageUploadDialog = ref(false)
const editTitleImageDialog = ref(false)
const editTitleImagePreviewDialog = ref(false)
const edit_header_image_preview = ref(false)
const edit_header_image = ref(false)
const workflow_id = ref("")

const blog = ref([])
const blog_categories = ref([])
const blog_tags = ref([])
const blog_sort = ref([])
const blog_status = ref([])
const users = ref([])
const blog_content = ref([])
const image_library = ref([])
const activeImageIndex = ref(0)
const displayGallery = ref(false)
const blog_changes = ref([])
const chatGptQuestions = ref([])
const chatGptDialog = ref(false)
const blog_title = ref(null);
const articles = ref()
const new_articles = ref()
const new_blog_categories = ref();
const blog_text_vorschlag = ref(null);
const blog_text_vorschlag_dialog = ref(false);
const title_image_cropper = ref();
const emits = defineEmits(['editorCallback'])
const title_image_cropper_preview = ref()
const disabled = ref(false);


const items = ref([
    {
        label: 'Änderungen zurücksetzen',
        icon: 'pi pi-refresh',
        command: () => {
            reset_changes()
        }
    },
    {
        label: 'Vorschau',
        icon: 'pi pi-eye',
        command: () => {
            preview()
        }
    },
    {
        label: 'Schließen',
        icon: 'pi pi-times',
        command: () => {
            close_tab()
        }
    }

]);

const MODULE = 'products';

const endpoints = {
    get: `${MODULE}/get`,
    getsingle: `${MODULE}/get-single`,
    savesingle: `${MODULE}/save-single`,
    getsystem: `get-system`,
    getusers: `get-users`,
    getcategories: `${MODULE}/categories/get`,
    gettags: `${MODULE}/tags`,
    save: `${MODULE}/save`,
    uploadsingle: `upload-image`,
    uploadlibrary: `${MODULE}/upload-library-image`,
    preview: `${MODULE}/preview/save`,
    getarticles: `articles/get`,
    get_pricespan: `${MODULE}/get-pricespan`,
}
provide('upload_endpoint', endpoints.uploadsingle);

onMounted(() => {
    loader.value = true;
    getBlogs().then(() => {
        loader.value = false;
    });
    fetchSystemAndUsers();
})

watch(loader, res => {
    Simplestore.spinner = res;
    disabled.value = res;
});
watch(workflow_id, res => {
    provide('workflow_id', res)
})

const fetchSystemAndUsers = async () => {
    try {
        const apiUrl = `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}`;
        const endpointsList = ['getsystem', 'getusers', 'getcategories', 'getarticles'];

        const requests = endpointsList.map(endpoint => axios.get(`${apiUrl}/${endpoints[endpoint]}`));
        const [systemResponse, usersResponse, categoriesResponse, articlesResponse] = await Promise.all(requests);

        [articles.value, users.value] = [articlesResponse.data, usersResponse.data];
        console.log(articles.value)
        blog_categories.value = categoriesResponse.data;

        blog_status.value = systemResponse.data.blog_status;

    } catch (error) {
        console.error("Error fetching system and users data:", error);
    }
};



const preview = () => {
    const workload = {
        redis_key: blog.value.redis_key,
        content: blog.value.content,
        title: blog.value.title,
        teaser: blog.value.teaser,
        header_image: blog.value.header_image,
    };

    axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.preview}`, workload)
        .then(response => {
            preview_dataset(blog.value.slug, SERVICE_URL, MODULE)
        })
}

const getBlogs = async () => {
    const payload = { redis_key: props.redis_key };
    try {
        const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsingle}`, payload);
        const { data } = response;

        blog.value = data;
        new_articles.value = data.articles;
        new_blog_categories.value = data.category;
        blog.value.category = data.category;
        workflow_id.value = data.workflow_id;
        blog_content.value = data.content;
        blog_title.value = data.title;
        blog_changes.value = data.changes;
        image_library.value = data.image_library;
        GPT_Primer(blog_title.value);

    } catch (error) {
        console.error("Error fetching blog data:", error);
    }
};

const GPT_Primer = (blog_title) => {
    chatGptQuestions.value = [
        {
            question: `Erstelle einen Text zum Thema ${blog_title}`,
            button_name: `Text automatsch erstellen`,
        },
        {
            question: `Erstelle einen Text zum Thema ${blog_title} und fasse ihn mit 100 Wörtern zusammen`,
            button_name: "Text-Zusammenfassung (100 Wörter)",
        },
    ];
}

const update_text_content = res => {
    blog.value.text_content = blog.value.text_content + res.chatGptAnswers;
    chatGptDialog.value = false;
}

const close_tab = () => {
    confirm2.require({
        message: 'Foo Sind Sie sicher, dass Sie diesen Artikel schliessen wollen? Alle nicht gespeicherten Änderungen gehen verloren.',
        header: 'Artikel schliessen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Artikel schliessen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            emits('editorCallback',
                {
                    "action": "close_tab",
                    "redis_key": props.redis_key
                }
            )
        }
    })
}

const showGallery = (index) => {
    activeImageIndex.value = index
    displayGallery.value = true
}

const update_template_data = (e) => {
    if (e.action == "save") {
        blog_content.value[e.index] = e.template_data
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gespeichert', life: 3000 })
    } else if (e.action == "delete") {
        blog_content.value.splice(e.index, 1)
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gelöscht', life: 3000 })
    } else if (e.action == "add") {
        blog_content.value.push(e.template_data)
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt hinzugefügt', life: 3000 })
    }
}

const save_changes = async () => {
    loader.value = true;
    const { value } = blog;
    value["content"] = blog_content.value;
    value["changes"] = blog_changes.value;
    value["image_library"] = image_library.value;
    value["category"] = new_blog_categories.value;
    value["articles"] = new_articles.value;

    try {
        const response = await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.savesingle}`,
            value
        );

        const { status } = response.data;

        if (status === "success" || status === "titlechanged") {
            toast.add({
                severity: "success",
                summary: `${value.title} gespeichert`,
                detail: "Änderungen wurden erfolgreich gespeichert",
                life: 3000,
            });
            loader.value = false;
        }

        // tmp hack
       // value["category"] = value.category[0];
    } catch (error) {
        console.error(error);
    }
};

const reset_changes = async () => {
    confirm2.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            try {
                const payload = { redis_key: props.redis_key };
                const response = await axios.post(
                    `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsingle}`,
                    payload
                );
                blog.value = response.data;
            } catch (error) {
                console.error(error);
            }
        },
    });
};

const onRowReorder = (e) => {
    if (e.dragIndex != e.dropIndex) {
        blog_content.value = e.value
    }
}

const crop_header_image = async (image, image_type) => {

    await uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 3000,
        });

        blog.value.header_image = res.data.image_url;
        edit_header_image.value = false;
    });
}

// const crop_header_image_preview = async (image, image_type) => {

//     await uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
//         toast.add({
//             severity: "success",
//             summary: "Erfolg",
//             detail: "Bild erfolgreich hochgeladen",
//             life: 3000,
//         });

//         blog.value.header_image_preview = res.data.image_url
//         edit_header_image_preview.value = false
//     });
// }

const UploadImage = (image, image_type) => {
    return new Promise((resolve, reject) => {
        uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
            resolve(res.data.image_url);
        }).catch(err => {
            reject(err);
        });
    });
}

const cropImage = async (image, image_type, reference) => {
    loader.value = true;
    UploadImage(image, image_type).then(res => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 2000,
        });
        [reference].value = res;
        loader.value = false;
    });
}


const generate_pricespan = async () => {

    const workload = new_articles.value
    const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.get_pricespan}`, { articles: workload });
    //console.log(response.data)
    blog.value.price_range = response.data.minimum_price
    blog.value.min_price = response.data.minimum_price
    blog.value.price_range_max = response.data.average_price
    blog.value.price_range_min = response.data.minimum_price
}

const libraryUploadGetData = (res) => {
    blog.value['image_library'].push({ image: res });
}

const libraryUploadidFinished = (res) => {
    if (res === true) {
        imageUploadDialog.value = false;
    }
}

const openEditDialog = res => {
    editTitleImageDialog.value = res;
}

const imageUploaded = res => {
    blog.value[res.type] = res.image;
}

</script>
