import {
    Bars3Icon,
    CalendarIcon,
    HomeIcon,
    ArrowUpIcon,
    ArrowUpOnSquareIcon,
    MagnifyingGlassCircleIcon,
    MapIcon,
    MegaphoneIcon,
    UserGroupIcon,
    XMarkIcon,
    InboxIcon,
    UsersIcon,
    CogIcon,
    DocumentIcon,
    QuestionMarkCircleIcon,
    ArrowLeftOnRectangleIcon,
    ArrowDownTrayIcon,
    EnvelopeIcon,
    BellIcon,
    EnvelopeOpenIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon,
} from '@heroicons/vue/24/outline';

export default [{ name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false, order: 0 },
{ name: 'Mailbox', href: '/inbox-dashboard2', icon: EnvelopeIcon, current: false, order: 1 },
{ name: 'Add-User', href: '/dashboard/add-user', icon: UserGroupIcon, current: false, order: 2 },
{ name: 'Einstellungen', href: '/dashboard/Einstellungen', icon: CogIcon, current: false, order: 3 },
{ name: 'Seiten', href: '/dashboard/Seiten', icon: DocumentIcon, current: false, order: 4 },
{ name: 'Hilfe', href: '#', icon: MegaphoneIcon, current: false, order: 7 },
{ name: 'Logout', href: '/logout', icon: ArrowLeftOnRectangleIcon, current: false, order: 6 },
    { name: 'System', href: '/dashboard/System', icon: CogIcon, current: false, order: 5 }];