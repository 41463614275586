<script setup>
import { ref, onMounted, defineProps } from 'vue'

//Threejs
import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

const props = defineProps({
  glburl: { type: String}
})

///////////////////
// Threejs Logic //
///////////////////

const get_random_id = () => {
    return Math.random().toString(16).slice(2)
}

const rendercontainer = ref()
const renderbox = ref()

const glbfile = ref(props.glburl + "?v=" + get_random_id())

const windowHeight = ref()
const windowWidth = ref()
const containerHeight = ref()
const containerWidth = ref()

function resizeVue() {
    windowHeight.value = window.innerHeight
    windowWidth.value = window.innerWidth
    containerHeight.value = rendercontainer.value.clientHeight
    containerWidth.value =  rendercontainer.value.clientWidth
    camera.aspect = containerWidth.value  / containerHeight.value
    camera.updateProjectionMatrix()
    renderer.setSize(containerWidth.value , containerHeight.value)
    console.log("width: ", rendercontainer.value.clientWidth)
}

const scene = new THREE.Scene()

//listener
window.addEventListener('resize', () => {
    camera.aspect = containerWidth.value  / containerHeight.value
    camera.updateProjectionMatrix()
    renderer.setSize(containerWidth.value , containerHeight.value)
    render()
}, false)

// Page Init
onMounted(() => {
    renderbox.value.appendChild(renderer.domElement)
    resizeVue()
    animate()
})

////////////////////
// Threejs Camera //
////////////////////

const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000)
camera.position.y = 1.5
camera.position.z = 3.2

//////////////////////
// Threejs Renderer //
//////////////////////

const renderer = new THREE.WebGLRenderer({ antialias: true })
renderer.setSize(containerWidth.value, containerHeight.value)
renderer.setPixelRatio(2)
renderer.setPixelRatio(window.devicePixelRatio)
renderer.toneMapping = THREE.LinearToneMapping
const color = 0xffffff
renderer.setClearColor(color)
renderer.outputEncoding = THREE.sRGBEncoding
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.toneMapping = THREE.ACESFilmicToneMapping

function render() {
    renderer.render(scene, camera)
}

//////////////////////
// Threejs Controls //
//////////////////////

const controls = new OrbitControls(camera, renderer.domElement)
controls.update()
controls.screenSpacePanning = true
controls.enableDamping = true

////////////////////
// Threejs Lights //
////////////////////


const ambientlight = new THREE.AmbientLight(0x404040, 0.8)
scene.add(ambientlight)

const fillLight1 = new THREE.DirectionalLight(0xd5ecff, 0.8)
fillLight1.position.set(2, 2, -2)
scene.add(fillLight1)

const fillLight2 = new THREE.DirectionalLight(0xffffff, 0.8)
fillLight2.position.set(-3, 2, -3)
scene.add(fillLight2)

const fillLight3 = new THREE.DirectionalLight(0xffffff, 0.8)
fillLight3.position.set(0, 1, 2)
scene.add(fillLight3)

const fillLight4 = new THREE.DirectionalLight(0xffffff, 0.8)
fillLight4.position.set(-3, 2, 3)
scene.add(fillLight4)

////////////////////
// Threejs Loader //
////////////////////

let urls = ["../../../../customers/motolino/assets/images/px.png", "../../../../assets/images/nx.png",
    "../../../../assets/images/py.png", "../../../../assets/images/ny.png",
    "../../../../assets/images/pz.png", "../../../../assets/images/nz.png"
]

let textureCube = new THREE.CubeTextureLoader().load(urls)
textureCube.format = THREE.RGBAFormat

const glb_scene = ref()

const three_loader = new GLTFLoader()
three_loader.load(String(glbfile.value), function(gltf) {

        gltf.scene.position.x = 0
        gltf.scene.position.y = -0.4
        gltf.scene.position.z = 0
        gltf.scene.rotation.y = 2 * Math.PI * (-30 / 360)
        scene.add(gltf.scene)
        glb_scene.value = gltf.scene

        glb_scene.value.traverse(function(object) {
            if (object.isMesh) {
                object.material.envMap = textureCube
                object.material.envMapIntensity = 1
                object.material.needsUpdate = true
                object.castShadow = true
                object.receiveShadow = true
            }
        })
    },

)

var animate = function() {
    requestAnimationFrame(animate)

    controls.update()
    render()
    //TWEEN.update()

}
</script>


<template>
    <div class="mt-3" ref="rendercontainer" style="width: 100%; height: 850px">
        <div ref="renderbox"></div>
    </div>
</template>