<template>
    <div class="hidden sm:block">
        <TabGroup>
            <TabList>
                <Tab as="template" v-slot="{ selected }">
                    <button :class="[selected ? 'border-primary-500 text-primary-600 border-transparent' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'group inline-flex items-center border-b-2 p-4 text-sm font-medium']">
                        <CircleStackIcon :class="[selected ? 'border-primary-500 text-primary-600' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
                        <span>Produkte</span>
                    </button>
                </Tab>
                <Tab as="template" v-slot="{ selected }">
                    <button :class="[selected ? 'border-primary-500 text-primary-600 border-transparent' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'group inline-flex items-center border-b-2 p-4 text-sm font-medium']">
                        <LinkIcon :class="[selected ? 'border-primary-500 text-primary-600' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
                        <span>Artikel</span>
                    </button>
                </Tab>
                <Tab as="template" v-slot="{ selected }">
                        <button :class="[selected ? 'border-primary-500 text-primary-600 border-transparent' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'group inline-flex items-center border-b-2 p-4 text-sm font-medium']">
                            <LinkIcon :class="[selected ? 'border-primary-500 text-primary-600' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
                            <span>Kategorien</span>
                        </button>
                    </Tab>
            </TabList>
            
            <TabPanels class="p-8">
                <TabPanel>
                    <MotolinoProdukte />
                </TabPanel>
                <TabPanel>
                    <MotolinoArticle />
                </TabPanel>
                <TabPanel>
                    <MotolinoCategories />
                </TabPanel>
            </TabPanels>
        </TabGroup>
    </div>


        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500">
                <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
            </select>
        </div>

        <!-- <div class="hidden sm:block">
            <div class="border-b border-gray-200">
                <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                    <a v-for="tab in tabs" :key="tab.name" :href="tab.href" :class="[tab.current ? 'border-primary-500 text-primary-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium']" :aria-current="tab.current ? 'page' : undefined">
                        <component :is="tab.icon" :class="[tab.current ? 'text-primary-500' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
                        <span>{{ tab.name }}</span>
                    </a>
                </nav>
            </div>
        </div> -->

</template>

<script setup>
import MotolinoArticle from "./Motolino-Products.vue"
import MotolinoProdukte from "./Motolino-Produkte.vue"
import MotolinoCategories from "./Motolino-Categories.vue"
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { BuildingOfficeIcon, UserIcon, CircleStackIcon, LinkIcon } from '@heroicons/vue/20/solid'

const tabs = [
    { name: 'My Account', href: '#', icon: UserIcon, current: false },
    { name: 'Company', href: '#', icon: BuildingOfficeIcon, current: false },
]
</script>