<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import { uploadSingleImage } from '@/core/var/crud'
import MenuButton from './Components/Menu-Button.vue'
import DataView from './Components/Data-View.vue'
import 'vue-advanced-cropper/dist/style.css'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ModalButtons from './Components/Modal-Menue.vue'
import ImageEditor from './Components/Image-Editor.vue'

const BLOCK_NAME = "image_text";
const NAME = 'Bild & Text';
const channel = ref(useCustomerStore().getCustomer.customer);

// local variables
const editDialog = ref(false)
// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    image_library: { type: Object, required: true },
    workflow_id: { type: String, required: true }
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const text_image_caption = ref(props.data.text_image_caption)
const html_content = ref(props.data.html_content)
const headline = ref(props.data.text_headline)
const floating = ref(props.data.floating)
const template = ref(BLOCK_NAME)
const image = ref(props.data.image)
const image_upload_url = ref()
const croppy = ref(null)
const edit = ref(false)

// functions
const edit_block = () => {
    editDialog.value = true
    edit.value = false
    image.value = props.data.image
}

const save_block = () => {

    const img = image_upload_url.value ? image_upload_url.value : image.value;

    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_image_caption": text_image_caption.value,
                "html_content": html_content,
                "text_headline": headline.value,
                "floating": floating,
                "image": img
            },
            "action": "save",
            "index": props.index
        }
    )
    editDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_image_caption": text_image_caption.value,
                "html_content": "",
                "text_headline": "",
                "floating": "",
                "image": image.value
            },
            "action": "add",
            "index": props.index
        }
    )
}

const crop_image = res => {
    croppy.value = res;
    image.value = res.getResult().canvas.toDataURL();
    upload_image()
    edit.value = false
}

const upload_image = async () => {
    await uploadSingleImage(croppy.value, props.index, props.workflow_id, channel.value, 'upload-image', true).then(res => {
        image_upload_url.value = res.data.image_url
    });
}
const toggleDialog = () => {
    editDialog.value = false
}


</script>
<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" button="pi-image" :name="NAME" />

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" :name="NAME">
        <div class="container mx-auto px-2 py-4">
            <h2 class="text-xl font-semibold mb-2 text-center">{{ headline }}</h2>
            <div :class="floating ? ['flex', 'flex-row-reverse'] : ['flex', 'flex-row']">
                <!-- Text column -->
                <div class="p-2 w-1/2">
                    <div class="text-start p-2 text-sm" v-html="html_content"></div>
                </div>
                <!-- Image column -->
                <div class="p-2 w-1/2">
                    <img :src="image" alt="" class="w-1/2">
                    <div class="pt-2 text-xs">{{ text_image_caption }}</div>
                </div>
            </div>
        </div>
        <div class="text-center p-2">
            <b>Bildausrichtung</b><br />
            <span v-if="floating">von rechts nach links (Bild | Text)</span>
            <span v-else>von links nach rechts (Text | Bild)</span>
        </div>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------- Edit Image -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">

        <div>
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
        </div>
        <div>
            <ImageEditor :edit="edit" :image_library="image_library" :image="image" @update="crop_image" />

            <div class="my-3">
                <label for="floating">Ausrichtung</label>
                <div>
                    <InputSwitch v-model="floating" id="floating" />
                </div>

                <span v-if="floating">von rechts nach links (Bild | Text)</span>
                <span v-if="!floating">von links nach rechts (Text | Bild)</span>

            </div>
            <div class="my-3">
                <label for="headline">Überschrift</label>
                <InputText v-model="headline" id="headline" type="text" :autoResize="true" />
            </div>
            <div class="my-3">
                <Editor editorStyle="width: 100%; min-height: 550px;" v-model="html_content" />
            </div>

            <div class="my-3">
                <label for="text_image_caption">Bildunterschrift</label>
                <Textarea v-model="text_image_caption" id="text_image_caption" type="text" :autoResize="true" />
            </div>
        </div>
    </Dialog>
</template>