<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import ThreejsViewer from './viewers/threejs_viewer.vue'

/////////////////////
// Interface Props //
/////////////////////

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const articles = ref()
const pre_save_articles = ref()

// filters
const filter_article = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_inline_editor = ref({
            'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
            'sku': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
            'price': {value: null, matchMode: FilterMatchMode.STARTS_WITH}
        })

const size_variants = [{id:"XS", name:"XS"}, {id:"S", name:"S"}, {id:"M", name:"M"}, {id:"L", name:"L"}, {id:"XL", name:"XL"}, {id:"XXL", name:"XXL"}]


//////////////////
// Data Sources //
//////////////////

const get_base_data = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/motolino/articles/get')
    .then(response => {
        articles.value = response.data
        pre_save_articles.value = JSON.parse(JSON.stringify(articles.value))
    })
}

onMounted(() => {
    get_base_data()
})

const edit = ref({
    "id": null,
    "name": null,
    "sku": null,
    "price": null,
    "default": null,
    "glb": null,
    "description": null,
    "description_de": null,
    "special": null,
    "rules": [],
    "variants_size": "",
    "variants_color": ""
})

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// CRUD Logic //////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const editArticleDialog = ref(false)
const newArticleDialog = ref(false)
const inlineEditorDialog = ref(false)
const loadThreejsViewer = ref(false)

const changes = ref(0)
const save_text = ref("Speichern")
const selected_glb_file = ref()
const selected_glb_file_name = ref()
const glb_fileUpload = ref()
const uploaded_url = ref()
const glb_url = ref()
const selected_icon_file = ref()
const selected_icon_file_name = ref()
const icon_fileUpload = ref()

const inlineEditor = () => {
    inlineEditorDialog.value = true
}

const onArticleRowEditSave = (event) => {
    let index = articles.value.findIndex((obj) => obj.sku == event.data.sku);
    articles.value[index][event.field] = event.newValue
    if(pre_save_articles.value[index][event.field] != event.newValue) {
        changes.value = changes.value + 1
        save_text.value = "Speichern (" + changes.value + ")"
        toast.add({ severity: 'success', summary: 'Angepasst', detail: event.newValue, life: 3000})
    }
}

const new_article = () => {
    edit.value = {
        "name": null,
        "price": null,
        "default": null,
        "glb": null,
        "icon": null,
        "description": null,
        "description_de": null,
        "special": null,
        "rules": [],
        "hex_color": null,
        "variants_size": null,
        "variants_color": null,
        "stock": null
    }
    selected_glb_file.value = null
    selected_glb_file_name.value = null
    glb_fileUpload.value = null
    selected_icon_file.value = null
    selected_icon_file_name.value = null
    icon_fileUpload.value = null
    newArticleDialog.value = true
}

const saveNewArticle = async () => {
    loader.value = true
    let max_sku = 0
    for (let key in articles.value)
        if(Number(articles.value[key].sku.substring(1,5)) >= max_sku){
            max_sku = Number(articles.value[key].sku.substring(1,5))
        }
    edit.value["sku"] = 'N' + ('0000' + (max_sku + 1)).slice(-4)
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    if (selected_glb_file.value != null){
        await upload_file(selected_glb_file.value, edit.value.sku + ".glb", "configurator/glb/")
        edit.value["glb"] = uploaded_url.value
    }
    if (selected_icon_file.value != null){
        await upload_file(selected_icon_file.value, edit.value.sku + ".png", "configurator/icon/")
        edit.value["icon"] = uploaded_url.value
    }
    articles.value.push(edit.value)
    newArticleDialog.value = false
    loader.value = false
}

const copyArticle = async (sku) => {
    loader.value = true
    for (let key in articles.value)
        if(articles.value[key].sku == sku){
            let copy = JSON.parse(JSON.stringify(articles.value[key]))
            let max_sku = 0
            for (let key in articles.value)
                if(Number(articles.value[key].sku.substring(1,5)) >= max_sku){
                    max_sku = Number(articles.value[key].sku.substring(1,5))
                }
            copy["sku"] = 'N' + ('0000' + (max_sku + 1)).slice(-4)
            copy["name"] = copy["name"] + " (Kopie)"
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            articles.value.push(copy)
            toast.add({ severity: 'success', summary: 'Kopiert', life: 3000})
        }
    loader.value = false
}

const saveEditArticle = async () => {
    loader.value = true
    for (let key in articles.value)
        if(articles.value[key].sku == edit.value.sku){
            articles.value[key] = edit.value
            if (selected_glb_file.value != null){
                await upload_file(selected_glb_file.value, edit.value.sku + ".glb", "configurator/glb/")
                articles.value[key]["glb"] = uploaded_url.value
            }
            if (selected_icon_file.value != null){
                await upload_file(selected_icon_file.value, edit.value.sku + ".png", "configurator/icon/")
                articles.value[key]["icon"] = uploaded_url.value
            }
        }
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    editArticleDialog.value = false
    loader.value = false
}

const editArticle = (sku) => {
    selected_glb_file.value = null
    selected_glb_file_name.value = null
    glb_fileUpload.value = null
    selected_icon_file.value = null
    selected_icon_file_name.value = null
    icon_fileUpload.value = null
    for (let key in articles.value)
        if(articles.value[key].sku == sku){
            edit.value = articles.value[key]
        }
    editArticleDialog.value = true
}

const delete_article = (sku) => {
    confirm.require({
        message: 'Bist du sicher, dass du den Artikel löschen möchtest',
        header: 'Artikel löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Artikel löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            for (let key in articles.value)
                if(articles.value[key].sku == sku){
                    articles.value.splice(key, 1)
                    changes.value = changes.value + 1
                    save_text.value = "Speichern (" + changes.value + ")"
                    toast.add({ severity: 'success', summary: 'Gelöscht', life: 3000})
                }
            }
    })
}

const save_articles = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/motolino/update-articles", articles.value)
        .then(response => {
            articles.value = response.data
            toast.add({ severity: 'success', summary: 'Gespeichert', life: 3000})
            changes.value = 0
            save_text.value = "Speichern"
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    });
}


const reset_articles = () => {
    articles.value = JSON.parse(JSON.stringify(pre_save_articles.value))
    changes.value = 0
    save_text.value = "Speichern"
}

const upload_file = async (file, filename, path) => {
    let payload = {
        "file": file,
        "filename": filename,
        "path": path
    }
    await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/motolino/admin-upload-file", payload)
        .then(response => {
            uploaded_url.value = response.data.url
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const view_3d_file = (url) => {
    glb_url.value = url
    loadThreejsViewer.value = true
}

function financial(x) {
  return Number.parseFloat(x).toFixed(2);
}

const glb_onSelectFile = (event) => {
    let reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = () => {
        selected_glb_file.value = reader.result
    };
    selected_glb_file_name.value = event.target.files[0]
}

const icon_onSelectFile = (event) => {
    let reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = () => {
        selected_icon_file.value = reader.result
    };
    selected_icon_file_name.value = event.target.files[0]
}

// rules

const add_rule = () => {
    console.log(edit.value["rules"])
    edit.value["rules"].push({
        "name": "",
        "value": "",
    })
}

const onRulesFieldSave = (event) => {
    edit.value.rules[event.index][event.field] = event.newValue
    toast.add({ severity: 'success', summary: 'Regel editiert:', detail: event.field + " zu " + event.newValue, life: 3000})
}

const onRulesRowReorder = (event) => {
    edit.value.rules = event.value
    toast.add({ severity: 'success', summary: 'Regeln neu geordnet', life: 3000})
}

</script>

<style scoped>
.p-inputtext {
    opacity: 1!important;
}
.p-multiselect-token  {
    color: #ffffff!important;
    background-color: #2196F3;
}
.p-datatable-header {
    padding: 0px!important;
}
</style>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <Toolbar>
        <template #start>
            <Button v-if="changes != 0" v-tooltip.bottom="'Anpassungen zurücksetzen'" @click="reset_articles()" class="mr-2 p-button-danger" icon="pi pi-refresh" />
            <Button :disabled="changes != 0 ? false : true" @click="save_articles()" v-tooltip.bottom="'Anpassungen Speichern'" :label="save_text" class="w-auto mr-4 p-button-success" icon="pi pi-save" />
            <Button label="Artikel" @click="new_article()" class="w-auto mr-2" type="button" icon="pi pi-plus" v-tooltip.right="'Neuen Fahrzeug hinzufügen'" />
            <Button label="Editor" @click="inlineEditor()" class="w-auto mr-1" type="button" icon="pi pi-pencil" v-tooltip.right="'Inline Editor'" />
        </template>
        <template #end>
            <Button type="button" icon="pi pi-cog" aria-controls="overlay_menu" class="p-button-rounded bg-blue-500" />
        </template>
    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_article['global'].value" placeholder="Suche" class="w-full" />
            </span>
        </template>
    </Toolbar>
    <DataTable class="mt-3" v-model:filters="filter_article" :value="articles" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,400,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column field="name" header="Fahrzeug" sortable>
            <template #body="slotProps">
                <div class="flex align-items-center">
                    <Avatar v-if="!slotProps.data.icon" icon="pi pi-qrcode" class="mr-2" style="background-color:#2196F3; color: #ffffff" shape="circle" />
                    <img v-if="slotProps.data.icon" :src="slotProps.data.icon" width="32" class="shadow-2 mr-2" style="vertical-align: middle; width: 45px; height: 45px; object-fit: cover; border-radius: 10%; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)" />
                    {{slotProps.data.name}} ({{slotProps.data.sku}})
                </div>
            </template>
        </Column>
        <Column field="price" header="Preis" sortable>
            <template #body="slotProps">
                CHF {{financial(slotProps.data.price)}}
            </template>
        </Column>
        <Column field="glb" header="3D" sortable>
            <template #body="slotProps">
                <Avatar v-if="!slotProps.data.glb" style="background-color: #D32F2F; color: #FFF" class="pi pi-times mr-2" v-tooltip.top="'3D Datei fehlt!'" shape="circle" />
                <Button v-if="slotProps.data.glb" @click="view_3d_file(slotProps.data.glb)" icon="pi pi-box" rounded text severity="success" />
            </template>
        </Column>
        <Column field="default">
            <template #body="slotProps">
                <div class="flex justify-content-center flex-wrap">
                    <Avatar v-if="slotProps.data.default" style="background-color: #2196F3; color: #FFF" v-tooltip.top="'Ist ein Standard Produkt'" icon="pi pi-check" class="mr-1" shape="circle" />
                    <Avatar v-if="slotProps.data.rules.length > 0" style="background-color: #eccfff; color: ##694382" v-tooltip.top="'Hat Sonderregeln'" :label="String(slotProps.data.rules.length)" class="mr-1" shape="circle" />
                    <Avatar v-if="slotProps.data.hex_color" class="mr-1" :style="'background-color: ' + slotProps.data.hex_color" shape="circle" />
                </div>
            </template>
        </Column>
        <Column header="">
            <template #body="slotProps">
                <Button @click="editArticle(slotProps.data.sku)" icon="pi pi-pencil" class="p-button-rounded p-button-text" />
                <Button @click="copyArticle(slotProps.data.sku)" icon="pi pi-copy" class="p-button-rounded p-button-text" />
                <Button @click="delete_article(slotProps.data.sku)" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" />
            </template>
        </Column>
    </DataTable>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <!------------------------------------------------------->
    <!------------------- New Article ----------------------->
    <!------------------------------------------------------->
    <Dialog v-model:visible="newArticleDialog" :style="{width: '650px'}" header="Artikel hinzufügen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="edit.name" />
                    <label for="name">Name</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="description" type="text" v-model="edit.description" />
                    <label for="description">Beschriftung</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="description_de" type="text" v-model="edit.description_de" />
                    <label for="description_de">Beschriftung (DE)</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputNumber id="price" v-model="edit.price" mode="currency" currency="CHF" locale="de-CH" :minFractionDigits="2" />
                    <label for="price">Preis</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="hex_color" type="text" v-model="edit.hex_color" />
                    <label for="hex_color">Farbe (HEX-Wert)</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label flex align-content-center flex-wrap">                               
                    <input type="file" @change="icon_onSelectFile" style="display: none" ref="icon_fileUpload" />
                    <Button :label="selected_icon_file_name ? selected_icon_file_name.name : 'Icon Bild hochladen'" @click="icon_fileUpload.click()" class="w-auto mr-2 mb-1" icon="pi pi-upload" />
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label flex align-content-center flex-wrap">
                    <input type="file" @change="glb_onSelectFile" style="display: none" ref="glb_fileUpload" />
                    <Button :label="selected_glb_file_name ? selected_glb_file_name.name : 'GLB Datei Hochladen'" @click="glb_fileUpload.click()" class="w-auto mr-2 mb-1" icon="pi pi-upload" />
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="flex align-content-center flex-wrap">
                    <InputSwitch id="default" v-model="edit.default" class="mr-3" />
                    <label v-if="!edit.default" for="default" class="align-content-center flex-wrap">Kein Standard Produkt</label>
                    <label v-if="edit.default" for="default" class="align-content-center flex-wrap">Ist ein Standard Produkt</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="special" type="text" v-model="edit.special" />
                    <label for="special">Special</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Dropdown id="variants_size" v-model="edit.variants_size" :options="size_variants" optionLabel="name" optionValue="id" placeholder="Grösse" />
                    <label for="variants_size">Variante Grösse</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="variants_color" v-model="edit.variants_color" />
                    <label for="variants_color">Variante Farbe</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputNumber id="variants_stock" mode="decimal" showButtons v-model="edit.stock" />
                    <label for="variants_stock">Lagerbestand</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="saveNewArticle()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="newArticleDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
    <!-------------------------------------------------------->
    <!------------------- Edit Article ----------------------->
    <!-------------------------------------------------------->
    <Dialog v-model:visible="editArticleDialog" :style="{width: '650px'}" header="Artikel bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="edit.name" />
                    <label for="name">Name</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="description" type="text" v-model="edit.description" />
                    <label for="description">Beschriftung</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="description_de" type="text" v-model="edit.description_de" />
                    <label for="description_de">Beschriftung (DE)</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="sku" type="text" v-model="edit.sku" />
                    <label for="sku">SKU</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputNumber id="price" v-model="edit.price" mode="currency" currency="CHF" locale="de-CH" :minFractionDigits="2" />
                    <label for="price">Preis</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="hex_color" type="text" v-model="edit.hex_color" />
                    <label for="hex_color">Farbe</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label flex align-content-center flex-wrap">                               
                    <input type="file" @change="icon_onSelectFile" style="display: none" ref="icon_fileUpload" />
                    <Button :label="selected_icon_file_name ? selected_icon_file_name.name : 'Icon Bild hochladen'" @click="icon_fileUpload.click()" class="w-auto mr-2 mb-1" icon="pi pi-upload" />
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label flex align-content-center flex-wrap">                               
                    <input type="file" @change="glb_onSelectFile" style="display: none" ref="glb_fileUpload" />
                    <Button :label="selected_glb_file_name ? selected_glb_file_name.name : 'GLB Datei hochladen'" @click="glb_fileUpload.click()" class="w-auto mr-2 mb-1" icon="pi pi-upload" />
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="flex align-content-center flex-wrap">
                    <InputSwitch id="default" v-model="edit.default" class="mr-3" />
                    <label v-if="!edit.default" for="default" class="align-content-center flex-wrap">Kein Standard Produkt</label>
                    <label v-if="edit.default" for="default" class="align-content-center flex-wrap">Ist ein Standard Produkt</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Dropdown id="variants_size" v-model="edit.variants_size" :options="size_variants" optionLabel="name" optionValue="id" placeholder="Grösse" />
                    <label for="variants_size">Variante Grösse</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="variants_color" v-model="edit.variants_color" />
                    <label for="variants_color">Variante Farbe</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputNumber id="variants_stock" mode="decimal" showButtons v-model="edit.stock" />
                    <label for="variants_stock">Lagerbestand</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="special" type="text" v-model="edit.special" />
                    <label for="special">Special</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <label>Regeln</label>
                <DataTable style="border: 1px solid #ced4da; border-radius: 3px;" :value="edit.rules" editMode="cell" @cell-edit-complete="onRulesFieldSave" @rowReorder="onRulesRowReorder" responsiveLayout="scroll">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="name" header="Name" style="width: 45%">
                        <template #editor="{ data, field }">
                            <InputText id="rule_name" type="text" v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span>{{ data[field] }}</span>
                        </template>
                    </Column>
                    <Column field="value" header="Wert" style="width: 45%">
                        <template #editor="{ data, field }">
                            <InputText id="rule_value" type="text" v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span>{{ data[field] }}</span>
                        </template>
                    </Column>
                </DataTable>
                <Button label="Regel Hinzufügen" @click="add_rule()" class="w-auto mt-2 p-button-success" icon="pi pi-plus" />
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="saveEditArticle()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="editArticleDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------------->
    <!------------------- Inline Editor ----------------------->
    <!--------------------------------------------------------->
    <Dialog header="Editor" v-model:visible="inlineEditorDialog" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '80vw'}" :maximizable="true" :modal="true">
        <DataTable :value="articles" editMode="cell" @cell-edit-complete="onArticleRowEditSave" filterDisplay="row" v-model:filters="filter_inline_editor" responsiveLayout="scroll">
            <Column field="sku" header="SKU" style="width: 5%"></Column>
            <Column field="name" header="Name" style="width: 30%"></Column>
            <Column field="description" header="Beschreibung (EN)" style="width: 30%">
                <template #editor="{ data, field }">
                    <InputText id="description" type="text" v-model="data[field]" class="w-full" />
                </template>
                <template #body="{ data, field }">
                    <span>{{ data[field] }}</span>
                </template>
            </Column>
            <Column field="description_de" header="Beschreibung (DE)" style="width: 30%">
                <template #editor="{ data, field }">
                    <InputText id="description_de" type="text" v-model="data[field]" class="w-full" />
                </template>
                <template #body="{ data, field }">
                    <span>{{ data[field] }}</span>
                </template>
            </Column>
            <Column field="price" header="Preis" style="width: 5%">
                <template #editor="{ data, field }">
                    <InputNumber id="price" v-model="data[field]" mode="currency" currency="CHF" locale="de-CH" :minFractionDigits="2" />
                </template>
                <template #body="{ data, field }">
                    <span>CHF {{ financial(data[field]) }}</span>
                </template>
            </Column>
        </DataTable>
    </Dialog>
    <!------------------------------------------------------------->
    <!------------------- 3d File Viewer -------------------------->
    <!------------------------------------------------------------->
    <Dialog v-model:visible="loadThreejsViewer" :style="{width: '850px'}" header="3D File Viewer" :modal="true" class="p-fluid">
        <ThreejsViewer :glburl="glb_url" />
    </Dialog>
</template>