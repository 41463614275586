<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import Simplestore from '@/core/var/Simplestore';
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import ThreejsViewer from './viewers/threejs_viewer.vue'

/////////////////////
// Interface Props //
/////////////////////

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

//////////////////
// Data Sources //
//////////////////

const configurator = ref()
const pre_save_configurator = ref()
const editVehicleDialog = ref(false)
const editTypeDialog = ref(false)
const editModelDialog = ref(false)
const newPartDialog = ref(false)
const editPartDialog = ref(false)
const sortPartsDialog = ref(false)
const loadVersionDialog = ref(false)
const newVehicleDialog = ref(false)
const newTypeDialog = ref(false)
const newModelDialog = ref(false)
const sortProductsDialog = ref(false)
const sortModelsDialog = ref(false)
const sortTypesDialog = ref(false)
const loadThreejsViewer = ref(false)
const edit = ref()
const selected_icon_file = ref()
const selected_icon_file_name = ref()
const icon_fileUpload = ref()
const uploaded_url = ref()
const articles = ref()
const changes = ref(0)
const save_text = ref("Speichern")
const versions = ref()
const order_columns = ref()
const order_key1 = ref()
const order_key2 = ref()
const order_key3 = ref()
const order_key4 = ref()
const glb_url = ref()
const selected_vehicle = ref("Vespa Primavera")
const vehicles_dropdown = ref([])

const part_types = ref([
    {name: 'Select', value: 'select'},
    {name: 'Multiselect', value: 'multiselect'}
])

const get_base_data = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/motolino/get-configurator")
        .then(response => {
            
            configurator.value = response.data
            pre_save_configurator.value = JSON.parse(JSON.stringify(configurator.value))
            console.log("configurator", configurator.value)
            for (let key in configurator.value) {
                vehicles_dropdown.value.push({"name": configurator.value[key].name})
            }
            console.log("vehicles_dropdown", vehicles_dropdown.value)
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    })

    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/motolino/articles/get')
    .then(response => {
        articles.value = response.data
    })
}

onMounted(() => {
    get_base_data()
})

const upload_file = async (file, filename, path) => {
    let payload = {
        "file": file,
        "filename": filename,
        "path": path
    }
    await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/motolino/admin-upload-file", payload)
        .then(response => {
            uploaded_url.value = response.data.url
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const view_3d_file = (url) => {
    glb_url.value = url
    loadThreejsViewer.value = true
}

const new_vehicle = () => {
    edit.value = {
        "name": null,
        "icon": null,
        "node_id": null,
        "types": []
    }
    newVehicleDialog.value = true
}


watch(loader, res => {
    Simplestore.spinner = res;
});

const save_new_vehicle = async () => {
    edit.value["node_id"] = get_random_id()
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    if (selected_icon_file.value != null){
        await upload_file(selected_icon_file.value, edit.value.node_id + ".png", "configurator/icons/")
        edit.value["icon"] = uploaded_url.value
    }
    configurator.value.push(edit.value)
    newVehicleDialog.value = false
}

const edit_vehicle = (id) => {
    edit.value = {"id": id}
    selected_icon_file.value = null
    selected_icon_file_name.value = null
    uploaded_url.value = ""
    for (let key in configurator.value)
        if (configurator.value[key].node_id == id){
            edit.value["name"] = configurator.value[key].name
        }
    editVehicleDialog.value = true
}

const save_vehicle = async (id) => {
    loader.value = true
    for (let key in configurator.value)
        if (configurator.value[key].node_id == id){
            configurator.value[key]["name"] = edit.value["name"]
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            if (selected_icon_file.value != null){
                await upload_file(selected_icon_file.value, id + ".png", "configurator/icons/")
                configurator.value[key]["icon"] = uploaded_url.value
            }
        }
    editVehicleDialog.value = false
    loader.value = false
}

const delete_vehicle = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du den Fahrzeug löschen möchtest?',
        header: 'Fahrzeug löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Fahrzeug löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            for (let key in configurator.value){
                if(configurator.value[key].node_id == id){
                    configurator.value.splice(key, 1)
                    changes.value = changes.value + 1
                    save_text.value = "Speichern (" + changes.value + ")"
                    toast.add({ severity: 'success', summary: 'Gelöscht', life: 3000})
                }
            }
        }
    })
}

const copy_vehicle = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du den Fahrzeug kopieren möchtest?',
        header: 'Fahrzeug kopieren',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Fahrzeug kopieren',
        acceptClass: 'p-button-warning',
        rejectLabel: 'Nein',
        accept: () => {
            for (let key in configurator.value){
                if(configurator.value[key].node_id == id){
                    let copy = JSON.parse(JSON.stringify(configurator.value[key]))
                    copy.node_id = get_random_id()
                    for (let key in copy.types){
                        copy.types[key].node_id = get_random_id()
                        for (let key2 in copy.types[key].models){
                            copy.types[key].models[key2].node_id = get_random_id()
                            for (let key3 in copy.types[key].models[key2].parts){
                                copy.types[key].models[key2].parts[key3].node_id = get_random_id()
                            }
                        }
                    }
                    configurator.value.push(copy)
                    changes.value = changes.value + 1
                    save_text.value = "Speichern (" + changes.value + ")"
                    toast.add({ severity: 'success', summary: 'Kopiert', life: 3000})
                }
            }
        }
    })
}

const new_type = (id) => {
    edit.value = {
        "name": null,
        "icon": null,
        "node_id": id,
        "models": []
    }
    newTypeDialog.value = true
}

const save_new_type = async (id) => {
    edit.value["node_id"] = get_random_id()
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    for (let key in articles.value)
        if (articles.value[key].sku == edit.value["sku"]){
            edit.value["glb"] = articles.value[key]["glb"]
        }
    for (let key in configurator.value){
        if (configurator.value[key].node_id == id){
            configurator.value[key]["types"].push(edit.value)
            newTypeDialog.value = false
        }
    }
}

const edit_type = (id) => {
    edit.value = {"id": id}
    for (let key in configurator.value){
        for (let key2 in configurator.value[key]["types"]){
            if (configurator.value[key]["types"][key2].node_id == id){
                edit.value["name"] = configurator.value[key]["types"][key2].name
            }
        }
    }
    editTypeDialog.value = true
}

const save_type = async (id) => {
    loader.value = true
    for (let key in configurator.value){
        for (let key2 in configurator.value[key]["types"]){
            if (configurator.value[key]["types"][key2].node_id == id){
                configurator.value[key]["types"][key2]["name"] = edit.value["name"]
                for (let key3 in articles.value)
                    if (articles.value[key3].sku == edit.value["sku"]){
                        configurator.value[key]["types"][key2]["glb"] = articles.value[key3]["glb"]
                        configurator.value[key]["types"][key2]["sku"] = articles.value[key3]["sku"]
                    }
            }
        }
    }
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    editTypeDialog.value = false
    loader.value = false
}

const delete_type = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du den Typ löschen möchtest?',
        header: 'Typ löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Artikel löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            for (let key in configurator.value){
                for (let key2 in configurator.value[key]["types"]){
                    if(configurator.value[key]["types"][key2].node_id == id){
                        configurator.value[key]["types"].splice(key2, 1)
                        changes.value = changes.value + 1
                        save_text.value = "Speichern (" + changes.value + ")"
                        toast.add({ severity: 'success', summary: 'Gelöscht', life: 3000})
                    }
                }
            }
        }
    })
}

const copy_type = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du den Fahrzeug kopieren möchtest?',
        header: 'Fahrzeugtyp kopieren',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Fahrzeugtyp kopieren',
        acceptClass: 'p-button-warning',
        rejectLabel: 'Nein',
        accept: () => {
            for (let key in configurator.value){
                for (let key2 in configurator.value[key]["types"]){
                    if(configurator.value[key]["types"][key2].node_id == id){
                        let copy = JSON.parse(JSON.stringify(configurator.value[key]["types"][key2]))
                        copy.node_id = get_random_id()
                        for (let key in copy.models){
                            copy.models[key].node_id = get_random_id()
                            for (let key2 in copy.models[key].parts){
                                copy.models[key].parts[key2].node_id = get_random_id()
                            }
                        }
                        configurator.value[key]["types"].push(copy)
                        changes.value = changes.value + 1
                        save_text.value = "Speichern (" + changes.value + ")"
                        toast.add({ severity: 'success', summary: 'Kopiert', life: 3000})
                    }
                }
            }
        }
    })
}

const sort_type = (id) => {
    for (let key in configurator.value){
        if (configurator.value[key].node_id == id){
            order_key1.value = key
            sortModelsDialog.value = true
        }
    }
}

const on_type_row_reorder = (event) => {
    configurator.value[order_key1.value]["types"] = event.value;
    toast.add({severity:'success', summary: 'Reihefolge angepasst', life: 3000});
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}

const new_model = (id) => {
    edit.value = {
        "name": null,
        "sku": null,
        "color": null,
        "hex_color": null,
        "consumption": null,
        "max_speed": null,
        "distance": null,
        "glb": null,
        "parts": [],
        "node_id": id
    }
    newModelDialog.value = true
}

const save_new_model = async (id) => {
    edit.value["node_id"] = get_random_id()
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    for (let key in articles.value)
        if (articles.value[key].sku == edit.value["sku"]){
            edit.value["glb"] = articles.value[key]["glb"]
            edit.value["price"] = articles.value[key]["price"]
        }
    for (let key in configurator.value){
        for (let key2 in configurator.value[key]["types"]){
            if (configurator.value[key]["types"][key2].node_id == id){
                configurator.value[key]["types"][key2]["models"].push(edit.value)
                newModelDialog.value = false
            }
        }
    }
}

const edit_model = (id) => {
    edit.value = {"id": id}
    for (let key in configurator.value){
        for (let key2 in configurator.value[key]["types"]){
            for (let key3 in configurator.value[key]["types"][key2]["models"]){
                if (configurator.value[key]["types"][key2]["models"][key3].node_id == id){
                    edit.value["name"] = configurator.value[key]["types"][key2]["models"][key3].name
                    edit.value["sku"] = configurator.value[key]["types"][key2]["models"][key3].sku
                    edit.value["color"] = configurator.value[key]["types"][key2]["models"][key3].color
                    edit.value["hex_color"] = configurator.value[key]["types"][key2]["models"][key3].hex_color
                    edit.value["consumption"] = configurator.value[key]["types"][key2]["models"][key3].consumption
                    edit.value["max_speed"] = configurator.value[key]["types"][key2]["models"][key3].max_speed
                    edit.value["distance"] = configurator.value[key]["types"][key2]["models"][key3].distance
                }
            }
        }
    }
    editModelDialog.value = true
}

const save_model = async (id) => {
    loader.value = true
    for (let key in configurator.value){
        for (let key2 in configurator.value[key]["types"]){
            for (let key3 in configurator.value[key]["types"][key2]["models"]){
                if (configurator.value[key]["types"][key2]["models"][key3].node_id == id){
                    configurator.value[key]["types"][key2]["models"][key3]["name"] = edit.value["name"]
                    configurator.value[key]["types"][key2]["models"][key3]["color"] = edit.value["color"]
                    configurator.value[key]["types"][key2]["models"][key3]["hex_color"] = edit.value["hex_color"]
                    configurator.value[key]["types"][key2]["models"][key3]["sku"] = edit.value["sku"]
                    configurator.value[key]["types"][key2]["models"][key3]["consumption"] = edit.value["consumption"]
                    configurator.value[key]["types"][key2]["models"][key3]["max_speed"] = edit.value["max_speed"]
                    configurator.value[key]["types"][key2]["models"][key3]["distance"] = edit.value["distance"]
                    changes.value = changes.value + 1
                    save_text.value = "Speichern (" + changes.value + ")"
                    for (let key4 in articles.value)
                        if (articles.value[key4].sku == edit.value["sku"]){
                            configurator.value[key]["types"][key2]["models"][key3]["price"] = articles.value[key4]["price"]
                            configurator.value[key]["types"][key2]["models"][key3]["glb"] = articles.value[key4]["glb"]
                        }
                }
            }
        }
    }
    editModelDialog.value = false
    loader.value = false
}

const delete_model = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du dieses Modell löschen möchtest?',
        header: 'Modell löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Artikel löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            for (let key in configurator.value){
                for (let key2 in configurator.value[key]["types"]){
                    for (let key3 in configurator.value[key]["types"][key2]["models"]){
                        if (configurator.value[key]["types"][key2]["models"][key3].node_id == id){
                            configurator.value[key]["types"][key2]["models"].splice(key3, 1)
                            changes.value = changes.value + 1
                            save_text.value = "Speichern (" + changes.value + ")"
                            toast.add({ severity: 'success', summary: 'Gelöscht', life: 3000})
                        }
                    }
                }
            }
        }
    })
}

const copy_model = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du den Modell kopieren möchtest?',
        header: 'Modell kopieren',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Modell kopieren',
        acceptClass: 'p-button-warning',
        rejectLabel: 'Nein',
        accept: () => {
            for (let key in configurator.value){
                for (let key2 in configurator.value[key]["types"]){
                    for (let key3 in configurator.value[key]["types"][key2]["models"]){
                        if(configurator.value[key]["types"][key2]["models"][key3].node_id == id){
                            let copy = JSON.parse(JSON.stringify(configurator.value[key]["types"][key2]["models"][key3]))
                            copy.node_id = get_random_id()
                            for (let key in copy.parts){
                                copy.parts[key].node_id = get_random_id()
                            }
                            configurator.value[key]["types"][key2]["models"].push(copy)
                            changes.value = changes.value + 1
                            save_text.value = "Speichern (" + changes.value + ")"
                            toast.add({ severity: 'success', summary: 'Kopiert', life: 3000})
                        }
                    }
                }
            }
        }
    })
}

const sort_model = (id) => {
    for (let key in configurator.value){
        for (let key2 in configurator.value[key]["types"]){
            if (configurator.value[key]["types"][key2].node_id == id){
                order_key1.value = key
                order_key2.value = key2
                sortModelsDialog.value = true
            }
        }
    }
}

const on_model_row_reorder = (event) => {
    configurator.value[order_key1.value]["types"][order_key2.value]["models"] = event.value;
    toast.add({severity:'success', summary: 'Reihefolge angepasst', life: 3000});
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}

const new_part = (id) => {
    edit.value = {
        "name": null,
        "articles": [],
        "node_id": id,
        "type": null
    }
    newPartDialog.value = true
}

const save_new_part = async (id) => {
    edit.value["node_id"] = get_random_id()
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    for (let key in configurator.value){
        for (let key2 in configurator.value[key]["types"]){
            for (let key3 in configurator.value[key]["types"][key2]["models"]){
                if (configurator.value[key]["types"][key2]["models"][key3].node_id == id){
                    configurator.value[key]["types"][key2]["models"][key3]["parts"].push(edit.value)
                    newPartDialog.value = false
                }
            }
        }
    }
}

const edit_part = (id) => {
    edit.value = {"id": id}
    for (let key in configurator.value){
        for (let key2 in configurator.value[key]["types"]){
            for (let key3 in configurator.value[key]["types"][key2]["models"]){
                for (let key4 in configurator.value[key]["types"][key2]["models"][key3]["parts"]){
                    if (configurator.value[key]["types"][key2]["models"][key3]["parts"][key4].node_id == id){
                        edit.value["name"] = configurator.value[key]["types"][key2]["models"][key3]["parts"][key4].name
                        edit.value["articles"] = configurator.value[key]["types"][key2]["models"][key3]["parts"][key4].articles
                        edit.value["type"] = configurator.value[key]["types"][key2]["models"][key3]["parts"][key4].type
                    }
                }
            }
        }
    }
    editPartDialog.value = true
}

const save_part = async (id) => {
    loader.value = true
    for (let key in configurator.value){
        for (let key2 in configurator.value[key]["types"]){
            for (let key3 in configurator.value[key]["types"][key2]["models"]){
                for (let key4 in configurator.value[key]["types"][key2]["models"][key3]["parts"]){
                    if (configurator.value[key]["types"][key2]["models"][key3]["parts"][key4].node_id == id){
                        configurator.value[key]["types"][key2]["models"][key3]["parts"][key4]["name"] = edit.value["name"]
                        configurator.value[key]["types"][key2]["models"][key3]["parts"][key4]["articles"] = edit.value["articles"]
                        configurator.value[key]["types"][key2]["models"][key3]["parts"][key4]["type"] = edit.value["type"]
                        changes.value = changes.value + 1
                        save_text.value = "Speichern (" + changes.value + ")"
                    }
                }
            }
        }
    }
    editPartDialog.value = false
    loader.value = false
}

const delete_part = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du dieses Teil löschen möchtest',
        header: 'Teil löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Teil löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            for (let key in configurator.value){
                for (let key2 in configurator.value[key]["types"]){
                    for (let key3 in configurator.value[key]["types"][key2]["models"]){
                        for (let key4 in configurator.value[key]["types"][key2]["models"][key3]["parts"]){
                            if (configurator.value[key]["types"][key2]["models"][key3]["parts"][key4].node_id == id){
                                configurator.value[key]["types"][key2]["models"][key3]["parts"].splice(key4, 1)
                                changes.value = changes.value + 1
                                save_text.value = "Speichern (" + changes.value + ")"
                                toast.add({ severity: 'success', summary: 'Gelöscht', life: 3000})
                            }
                        }
                    }
                }
            }
        }
    })
}

const sort_part = (id) => {
    for (let key in configurator.value){
        for (let key2 in configurator.value[key]["types"]){
            for (let key3 in configurator.value[key]["types"][key2]["models"]){
                if (configurator.value[key]["types"][key2]["models"][key3].node_id == id){
                    order_key1.value = key
                    order_key2.value = key2
                    order_key3.value = key3
                    sortPartsDialog.value = true
                }
            }
        }
    }
}

const on_part_row_reorder = (event) => {
    configurator.value[order_key1.value]["types"][order_key2.value]["models"][order_key3.value]["parts"] = event.value;
    toast.add({severity:'success', summary: 'Reihefolge angepasst', life: 3000});
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}

const sort_articles = ref()

const sort_product = (id) => {
    for (let key in configurator.value){
        for (let key2 in configurator.value[key]["types"]){
            for (let key3 in configurator.value[key]["types"][key2]["models"]){
                for (let key4 in configurator.value[key]["types"][key2]["models"][key3]["parts"]){
                    if (configurator.value[key]["types"][key2]["models"][key3]["parts"][key4].node_id == id){
                        order_key1.value = key
                        order_key2.value = key2
                        order_key3.value = key3
                        order_key4.value = key4
                        sort_articles.value = []
                        for (let key5 in configurator.value[key]["types"][key2]["models"][key3]["parts"][key4].articles){
                            sort_articles.value.push({"sku": configurator.value[key]["types"][key2]["models"][key3]["parts"][key4].articles[key5]})
                        }
                        sortProductsDialog.value = true
                    }
                }
            }
        }
    }
}

const on_product_row_reorder = (event) => {
    let sorted_articles = []
    for (let key in event.value){
        sorted_articles.push(event.value[key].sku)
    }
    configurator.value[order_key1.value]["types"][order_key2.value]["models"][order_key3.value]["parts"][order_key4.value].articles = sorted_articles
    toast.add({severity:'success', summary: 'Reihefolge angepasst', life: 3000})
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}

const save_configurator = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/motolino/update-configurator", configurator.value)
        .then(response => {
            configurator.value = response.data
            toast.add({ severity: 'success', summary: 'Gespeichert', life: 3000})
            changes.value = 0
            save_text.value = "Speichern"
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    });
}

const reset_configurator = () => {
    window.location.reload()
}

const load_version = async (filename) => {
    loader.value = true
    let file = {"filename": filename}
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/motolino/load-configurator-archive", file)
        .then(response => {
            configurator.value = response.data
            toast.add({ severity: 'success', summary: 'Archiv-Datei geladen', detail: filename + " wurde erfolgreich geladen", life: 3000})
            changes.value = 0
            save_text.value = "Speichern"
            loader.value = false
            loadVersionDialog.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    });
}

const get_versions = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/motolino/get-configurator-archive")
        .then(response => {
            versions.value = response.data
            loadVersionDialog.value = true
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    });
}

const publish_configurator = () => {
    confirm.require({
        message: 'Bist du sicher, dass du diese Version publizieren möchtest?',
        header: 'Version publizieren?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, publizieren',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/motolino/publish-configurator", configurator.value)
                .then(response => {
                    toast.add({ severity: 'success', summary: 'Version publiziert: '+ response.data.statis, detail: "Diese Version wurde erfolgreich publiziert", life: 3000})
                    loader.value = false
                }).catch(error => {
                console.error("There was an error!", error.message);
            })
        }
    })
}

const get_random_id = () => {
    return Math.random().toString(16).slice(2)
}

const icon_onSelectFile = (event) => {
    var reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = () => {
        selected_icon_file.value = reader.result
    };
    selected_icon_file_name.value = event.target.files[0]
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

function financial(x) {
  return Number.parseFloat(x).toFixed(2);
}

function bytes_for_humans(bytes, decimals = 2) {
    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
    let i = 0
    for (i; bytes > 1024; i++) {
        bytes /= 1024;
    }
    return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i]
}

const get_product_values = (product, keyname) => {
    for (let key in articles.value){
        if (articles.value[key].sku == product){
            return articles.value[key][keyname]
        }
    }
}

</script>

<style lang="scss" scoped>
.accordion-custom {
    i, span {
        vertical-align: middle;
    }

    span {
        margin: 0 .5rem;
    }
}

.p-accordion p {
    line-height: 1.5;
    margin: 0;
}
</style>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <!-- <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" /> -->
    <Toast />
    <ConfirmDialog></ConfirmDialog>
    <Toolbar>
        <template #start>
            <Button v-if="changes != 0" v-tooltip.bottom="'Anpassungen zurücksetzen'" @click="reset_configurator()" class="mr-2 p-button-danger" icon="pi pi-refresh" />
            <Button :disabled="changes != 0 ? false : true" @click="save_configurator()" v-tooltip.bottom="'Anpassungen Speichern'" :label="save_text" class="w-auto mr-4 p-button-success" icon="pi pi-save" />
            <Button @click="publish_configurator()" :disabled="changes == 0 ? false : true" class="mr-2 p-button-warning" v-tooltip.bottom="'Publizieren'" icon="pi pi-cloud-upload" />
            <Button @click="get_versions()" class="mr-4 p-button-help" v-tooltip.bottom="'Vorherige Version laden'" icon="pi pi-history" />
            <Button @click="new_vehicle()" label="Fahrzeug hinzufügen" class="w-auto mr-2 p-button" v-tooltip.top="'Ein Vespa hinzufügen'" icon="pi pi-plus" />
            <Dropdown v-model="selected_vehicle" :options="vehicles_dropdown" optionLabel="name" optionValue="name" class="w-auto mr-2" :filter="true" />
        </template>
        <template #end>
            <Button type="button" icon="pi pi-cog" aria-controls="overlay_menu" class="p-button-rounded bg-blue-500" />
        </template>
    </Toolbar>
    <!---------------------------------------------------->
    <!-------------- #### Vehicles #### ------------------>
    <!---------------------------------------------------->
    <template v-for="(vehicle, key) in configurator" :key="key">
    <Accordion class="accordion-custom mt-3">
        <template v-if="vehicle.name == selected_vehicle">
            <AccordionTab>
                <template #header>
                    <i class="pi pi-car"></i>
                    <span style="font-size: 130%;">{{vehicle.name}}</span>
                    <Avatar :label="String(vehicle.types.length)" class="mr-2" shape="circle"  />
                    <Avatar v-if="!vehicle.icon" style="background-color: #D32F2F; color: #FFF" icon="pi pi-times" class="mr-2" v-tooltip.top="'Icon Datei fehlt!'" shape="circle" />
                    <a v-if="vehicle.icon" target="_blank" :href="vehicle.icon" class="mr-2" v-tooltip.top="'Icon Datei'"><Avatar style="background-color: #689F38; color: #FFF" icon="pi pi-image" shape="circle" /></a>
                    <!-- <Avatar v-for="(colors, key) in config.types.models" :key="key" class="mr-2" :style="'background-color: ' + colors.hex_color" :label="colors.name" shape="circle"  /> -->
                    <Button @click="edit_vehicle(vehicle.node_id)" v-tooltip.top="'Fahrzeug editieren'" rounded text icon="pi pi-pencil" />
                    <Button @click="new_type(vehicle.node_id)" v-tooltip.top="'Fahrzeugtyp hinzufügen'" rounded text icon="pi pi-plus-circle" />
                    <Button @click="copy_vehicle(vehicle.node_id)" v-tooltip.top="'Fahrzeugtyp hinzufügen'" rounded text icon="pi pi-copy" />
                    <Button @click="sort_type(vehicle.node_id)" v-tooltip.top="'Fahrzeugtypen sortieren'" rounded text icon="pi pi-sort" />
                    <Button @click="delete_vehicle(vehicle.node_id)" v-tooltip.top="'Fahrzeug löschen'" rounded text severity="danger" icon="pi pi-trash" />
                </template>
                <!------------------------------------------------->
                <!-------------- #### Types #### ------------------>
                <!------------------------------------------------->
                <Accordion class="accordion-custom">
                    <template v-for="(type, key) in vehicle.types" :key="key">
                        <AccordionTab>
                            <template #header>
                                <i class="pi pi-car" />
                                <span class="mr-3">{{type.name}}</span>
                                <Avatar :label="String(type.models.length)" class="mr-2" shape="circle"  />
                                <Avatar v-if="!type.glb" style="background-color: #D32F2F; color: #FFF" icon="pi pi-times" class="mr-2" v-tooltip.top="'3D Datei fehlt!'" shape="circle" />
                                <Button v-if="type.glb" @click="view_3d_file(type.glb)" icon="pi pi-box" rounded text severity="success" />
                                <Button @click="edit_type(type.node_id)" v-tooltip.top="'Fahrzeugtyp editieren'" rounded text icon="pi pi-pencil" />
                                <Button @click="new_model(type.node_id)" v-tooltip.top="'Modell hinzufügen'" rounded text icon="pi pi-plus-circle" />
                                <Button @click="copy_type(type.node_id)" v-tooltip.top="'Fahrzeugtyp kopieren'" rounded text icon="pi pi-copy" />
                                <Button @click="sort_model(type.node_id)" v-tooltip.top="'Modelle sortieren'" rounded text icon="pi pi-sort" />
                                <Button @click="delete_type(type.node_id)" v-tooltip.top="'Fahrzeugtyp löschen'" rounded text severity="danger" icon="pi pi-trash" />
                            </template>
                            <!-------------------------------------------------->
                            <!-------------- #### Models #### ------------------>
                            <!-------------------------------------------------->
                            <Accordion class="accordion-custom">
                                <template v-for="(model, key) in type.models" :key="key">
                                    <AccordionTab>
                                        <template #header>
                                            <i class="pi pi-qrcode" />
                                            <span class="mr-3">{{model.name}}</span>
                                            <Avatar :label="String(model.parts.length)" class="mr-2" shape="circle"  />
                                            <Avatar class="mr-2" :style="'background-color: ' + model.hex_color" shape="circle"  />
                                            <Avatar v-if="!model.glb" style="background-color: #D32F2F; color: #FFF" class="mr-2" icon="pi pi-times" v-tooltip.top="'3D Datei fehlt!'" shape="circle" />
                                            <Button v-if="model.glb" @click="view_3d_file(model.glb)" icon="pi pi-box" rounded text severity="success" />
                                            <Chip :label="'SKU: ' + model.sku" class="mr-2 custom-chip" />
                                            <Chip :label="'CHF : ' + financial(model.price)" class="mr-2 custom-chip" style="background-color: #ffd8b2; color: #805b36" />
                                            <Chip :label="model.color" icon="pi pi-palette" v-tooltip.top="'Farbe'" class="mr-1" style="background-color: #eccfff; color: ##694382" />
                                            <Button @click="edit_model(model.node_id)" v-tooltip.top="'Modell editieren'" rounded text icon="pi pi-pencil" />
                                            <Button @click="new_part(model.node_id)" v-tooltip.top="'Teil hinzufügen'" rounded text icon="pi pi-plus-circle" />
                                            <Button @click="copy_model(model.node_id)" v-tooltip.top="'Modell kopieren'" rounded text icon="pi pi-copy" />
                                            <Button @click="sort_part(model.node_id)" v-tooltip.top="'Teile sortieren'" rounded text icon="pi pi-sort" />
                                            <Button @click="delete_model(model.node_id)" v-tooltip.top="'Fahrzeugtyp löschen'" rounded text severity="danger" icon="pi pi-trash" />
                                        </template>
                                        <!------------------------------------------------->
                                        <!-------------- #### Parts #### ------------------>
                                        <!------------------------------------------------->
                                        <Accordion class="accordion-custom">
                                            <template v-for="(part, key) in model.parts" :key="key">
                                                <AccordionTab>
                                                    <template #header>
                                                        <i class="pi pi-cog" />
                                                        <span>{{part.name}}</span>
                                                        <Avatar :label="String(part.articles.length)" class="mr-2" shape="circle"  />
                                                        <Chip :label="'Type: ' + part.type" class="mr-2 custom-chip" style="background-color: #b3e5fc; color: #23547b;" />
                                                        <Button @click="edit_part(part.node_id)" v-tooltip.top="'Teil editieren'" rounded text icon="pi pi-pencil" />
                                                        <Button @click="sort_product(part.node_id)" v-tooltip.top="'Artikel sortieren'" rounded text icon="pi pi-sort" />
                                                        <Button @click="delete_part(part.node_id)" v-tooltip.top="'Teil löschen'" rounded text severity="danger" icon="pi pi-trash" />
                                                    </template>
                                                    <!---------------------------------------------------->
                                                    <!-------------- #### Products #### ------------------>
                                                    <!---------------------------------------------------->
                                                    <template v-for="article in part.articles" :key="article">
                                                        <div class="card">
                                                            <div class="flex flex-wrap card-container">
                                                                <div class="flex align-items-center justify-content-center mt-1 border-round p-2" style="background-color: #f8f9fa; font-weight: 600;">
                                                                    <i class="pi pi-qrcode" />
                                                                    <span class="mr-3">{{get_product_values(article, 'description')}}</span>
                                                                    <!-- <Avatar v-if="!get_product_values(article, 'glb')" style="background-color: #D32F2F; color: #FFF" icon="pi pi-times" class="mr-2" v-tooltip.top="'3D Datei fehlt!'" shape="circle" /> -->
                                                                    <!-- <Button v-if="get_product_values(article, 'glb')" @click="view_3d_file(get_product_values(article, 'glb'))" icon="pi pi-box" rounded text severity="success" /> -->
                                                                    <Chip :label="'SKU: ' + get_product_values(article, 'sku')" class="mr-2 custom-chip" />
                                                                    <Chip :label="'CHF : ' + financial(get_product_values(article, 'price'))" class="mr-2 custom-chip" style="background-color: #ffd8b2; color: #805b36" />
                                                                    <!-- <Chip v-if="get_product_values(article, 'special')" :label="get_product_values(article, 'special')" icon="pi pi-star" v-tooltip.top="'Sonderregeln'" class="mr-2" style="background-color: #eccfff; color: ##694382" /> -->
                                                                    <!-- <Avatar v-if="get_product_values(article, 'default')" style="background-color: #2196F3; color: #FFF" icon="pi pi-check" shape="circle" /> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </AccordionTab>
                                            </template>
                                        </Accordion>
                                    </AccordionTab>
                                </template>
                            </Accordion>
                        </AccordionTab>
                    </template>
                </Accordion>
            </AccordionTab>
        </template>
    </Accordion>
    </template>
    <!--------------------------------------------------->
    <!-------------- #### Dialogs #### ------------------>
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!-------------- #### Vehicle #### ------------------>
    <!--------------------------------------------------->

    <Dialog v-model:visible="editVehicleDialog" :style="{width: '650px'}" header="Fahrzeug bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="edit.name" />
                    <label for="name">Name</label>
                </span>
            </div>
            <div class="field col-12">
                <span class="p-float-label flex align-content-center flex-wrap">
                    <input type="file" @change="icon_onSelectFile" style="display: none" ref="icon_fileUpload" />
                    <Button :label="selected_icon_file_name ? selected_icon_file_name.name : 'Icon Datei Hochladen'" @click="icon_fileUpload.click()" class="w-auto mr-2 mb-1" icon="pi pi-upload" />
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="save_vehicle(edit.id)" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="editVehicleDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="newVehicleDialog" :style="{width: '650px'}" header="Fahrzeug hinzufügen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="edit.name" />
                    <label for="name">Name</label>
                </span>
            </div>
            <div class="field col-12">
                <span class="p-float-label flex align-content-center flex-wrap">
                    <input type="file" @change="icon_onSelectFile" style="display: none" ref="icon_fileUpload" />
                    <Button :label="selected_icon_file_name ? selected_icon_file_name.name : 'Icon Datei Hochladen'" @click="icon_fileUpload.click()" class="w-auto mr-2 mb-1" icon="pi pi-upload" />
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="save_new_vehicle()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="newVehicleDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>

    <!------------------------------------------------>
    <!-------------- #### Type #### ------------------>
    <!------------------------------------------------>

    <Dialog v-model:visible="editTypeDialog" :style="{width: '650px'}" header="Typ bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="edit.name" />
                    <label for="name">Name</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Dropdown v-model="edit.sku" :options="articles" optionLabel="name" optionValue="sku" class="w-auto mr-2" :filter="true" />
                    <label for="sku">Artikel</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="save_type(edit.id)" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="editTypeDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="newTypeDialog" :style="{width: '650px'}" header="Typ hinzufügen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="edit.name" />
                    <label for="name">Name</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Dropdown v-model="edit.sku" :options="articles" optionLabel="name" optionValue="sku" class="w-auto mr-2" :filter="true" />
                    <label for="sku">Artikel</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="save_new_type(edit.node_id)" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="newTypeDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="sortTypesDialog" :style="{width: '650px'}" header="Fahrzeugtypen sortieren" :modal="true" class="p-fluid">
        <DataTable :value="configurator[order_key1].types" @rowReorder="on_type_row_reorder" responsiveLayout="scroll">
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column field="name" header="Name" />
        </DataTable>
    </Dialog>

    <!-------------------------------------------------->
    <!-------------- #### Models #### ------------------>
    <!-------------------------------------------------->

    <Dialog v-model:visible="editModelDialog" :style="{width: '650px'}" header="Modell bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="edit.name" />
                    <label for="name">Name</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Dropdown v-model="edit.sku" :options="articles" optionLabel="name" optionValue="sku" class="w-auto mr-2" :filter="true" />
                    <label for="sku">Artikel</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="color" type="text" v-model="edit.color" />
                    <label for="color">Farbe</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="max_speed" type="text" v-model="edit.max_speed" />
                    <label for="max_speed">Max. Geschwindigkeit</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="distance" type="text" v-model="edit.distance" />
                    <label for="distance">Reichweite</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="consumption" type="text" v-model="edit.consumption" />
                    <label for="consumption">Verbrauch</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="color" type="text" v-model="edit.hex_color" />
                    <label for="color">Hex Farbe</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <Avatar class="mr-2" :style="'background-color: ' + edit.hex_color" shape="circle"  />
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="save_model(edit.id)" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="editModelDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="newModelDialog" :style="{width: '650px'}" header="Modell hinzufügen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="edit.name" />
                    <label for="name">Name</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Dropdown v-model="edit.sku" :options="articles" optionLabel="name" optionValue="sku" class="w-auto mr-2" :filter="true" />
                    <label for="sku">Artikel</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="color" type="text" v-model="edit.color" />
                    <label for="color">Farbe</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="max_speed" type="text" v-model="edit.max_speed" />
                    <label for="max_speed">Max. Geschwindigkeit</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="distance" type="text" v-model="edit.distance" />
                    <label for="distance">Reichweite</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="consumption" type="text" v-model="edit.consumption" />
                    <label for="consumption">Verbrauch</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="color" type="text" v-model="edit.hex_color" />
                    <label for="color">Hex Farbe</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <Avatar class="mr-2" :style="'background-color: ' + edit.hex_color" shape="circle"  />
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="save_new_model(edit.node_id)" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="newModelDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="sortModelsDialog" :style="{width: '650px'}" header="Modelle sortieren" :modal="true" class="p-fluid">
        <DataTable :value="configurator[order_key1].types[order_key2].models" @rowReorder="on_model_row_reorder" responsiveLayout="scroll">
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column field="name" header="Name" />
        </DataTable>
    </Dialog>

    <!---------------------------------------------------->
    <!------------------- Parts -------------------------->
    <!---------------------------------------------------->

    <Dialog v-model:visible="editPartDialog" :style="{width: '650px'}" header="Teil bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="edit.name" />
                    <label for="name">Name</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <MultiSelect v-model="edit.articles" :options="articles" optionLabel="name" optionValue="sku" placeholder="Artikel auswählen" :filter="true" display="chip" />
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Dropdown v-model="edit.type" :options="part_types" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                    <label for="sku">Typ</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="save_part(edit.id)" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="editPartDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="newPartDialog" :style="{width: '650px'}" header="Teil hinzufügen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="edit.name" />
                    <label for="name">Name</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <MultiSelect v-model="edit.articles" :options="articles" optionLabel="name" optionValue="sku" placeholder="Artikel auswählen" :filter="true" display="chip" />
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Dropdown v-model="edit.type" :options="part_types" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                    <label for="sku">Typ</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="save_new_part(edit.node_id)" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="newPartDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="sortPartsDialog" :style="{width: '650px'}" header="Teile sortieren" :modal="true" class="p-fluid">
        <DataTable :value="configurator[order_key1].types[order_key2].models[order_key3].parts" @rowReorder="on_part_row_reorder" responsiveLayout="scroll">
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column field="name" header="Name" />
        </DataTable>
    </Dialog>

    <Dialog v-model:visible="sortProductsDialog" :style="{width: '650px'}" header="Artikel sortieren" :modal="true" class="p-fluid">
        <DataTable :value="sort_articles" @rowReorder="on_product_row_reorder" responsiveLayout="scroll">
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column field="sku" header="SKU" />
        </DataTable>
    </Dialog>

    <!---------------------------------------------------->
    <!------------------- Versions ----------------------->
    <!---------------------------------------------------->

    <Dialog v-model:visible="loadVersionDialog" :style="{width: '850px'}" header="Version laden" :modal="true" class="p-fluid">
        <div class="col-12">
            <DataTable :value="versions" :paginator="true" :rows="10" :rowsPerPageOptions="[10, 20, 50]" :rowHover="true">
                <Column field="blob_name" header="Datei" sortable>
                    <template #body="slotProps">
                        <i class="pi pi-file mr-2"></i><a class="mb-1" :href="slotProps.data.blob_url" target="_blank" style="white-space: nowrap;">{{slotProps.data.blob_name}}</a>
                    </template>
                </Column>
                <Column field="blob_size" header="Grösse">
                    <template #body="slotProps">
                        <span>{{bytes_for_humans(slotProps.data.blob_size, 1)}}</span>
                    </template>
                </Column>
                <Column field="blob_updated" header="Datum">
                </Column>
                <Column>
                    <template #body="slotProps">
                        <Button @click="load_version(slotProps.data.blob_name)" icon="pi pi-history" class="p-button-rounded p-button-warning" />
                    </template>
                </Column>
            </DataTable>
        </div>
        <div class="col-12 mt-3">
            <Button label="Abbrechen" @click="loadVersionDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
        </div>
    </Dialog>

    <!------------------------------------------------------------->
    <!------------------- 3d File Viewer -------------------------->
    <!------------------------------------------------------------->

    <Dialog v-model:visible="loadThreejsViewer" :style="{width: '850px'}" header="3D File Viewer" :modal="true" class="p-fluid">
        <ThreejsViewer :glburl="glb_url" />
    </Dialog>
</template>
