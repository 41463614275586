import { defineStore } from 'pinia';
import jsondata from '../../../package.json';

export const useCustomerStore = defineStore('customer', {
    state: () => ({ 
        customer: {} 
    }),
    getters: {
        getCustomer(state) {
            return state.customer;
        },
    },
    actions: {
        increment() {
            this.count++
        },
        setCustomer(customerdata) {
            this.customer = customerdata;
            this.customer.fullname = process.env.VUE_APP_CUSTOMER;
            this.customer.appname = jsondata.callname;
        },
    },
})
